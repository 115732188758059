import { MouseEvent, MouseEventHandler } from "react";
import { Filter, UserFilterReducerActionType } from "./hooks/useUserFilter";
import { UserListRow } from "./UserListRow";

type UserListProps = {
    selectUser: Function;
    userList: any;
    loading: boolean | undefined;
    goToEdit: MouseEventHandler<HTMLSpanElement>;
    filterWidget: JSX.Element;
    dispatch: Function;
    actions: {
        setSortBy: (sortingKey: string) => UserFilterReducerActionType;
    };
    filters: Filter;
    loadingStatus: boolean;
};

export default function UsersList({
    selectUser,
    userList,
    loading,
    goToEdit,
    filterWidget,
    dispatch,
    actions,
    filters,
    loadingStatus,
}: UserListProps) {
    const { setSortBy } = actions;

    const goToEditHandler = (
        id: string | number,
        event: MouseEvent<HTMLSpanElement>
    ) => {
        selectUser(id);
        goToEdit(event);
    };

    const sortByHandler = (sortingKey: string) => {
        dispatch(setSortBy(sortingKey));
    };

    return loading ? (
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    ) : (
        <>
            {filterWidget}
            <div className="user-list-wrapper__header">
                <h2 onClick={() => sortByHandler("id")}>
                    Id
                    {filters.sortingParams.key === "id" ? (
                        <span>
                            {filters.sortingParams.direction ? (
                                <i className="bi bi-caret-up-fill"></i>
                            ) : (
                                <i className="bi bi-caret-down-fill"></i>
                            )}
                        </span>
                    ) : null}
                </h2>
                <h2 onClick={() => sortByHandler("firstName")}>
                    First Name
                    {filters.sortingParams.key === "firstName" ? (
                        <span>
                            {filters.sortingParams.direction ? (
                                <i className="bi bi-caret-up-fill"></i>
                            ) : (
                                <i className="bi bi-caret-down-fill"></i>
                            )}
                        </span>
                    ) : null}
                </h2>
                <h2 onClick={() => sortByHandler("lastName")}>
                    Last Name
                    {filters.sortingParams.key === "lastName" ? (
                        <span>
                            {filters.sortingParams.direction ? (
                                <i className="bi bi-caret-up-fill"></i>
                            ) : (
                                <i className="bi bi-caret-down-fill"></i>
                            )}
                        </span>
                    ) : null}
                </h2>
                <h2 onClick={() => sortByHandler("email")}>
                    Email
                    {filters.sortingParams.key === "email" ? (
                        <span>
                            {filters.sortingParams.direction ? (
                                <i className="bi bi-caret-up-fill"></i>
                            ) : (
                                <i className="bi bi-caret-down-fill"></i>
                            )}
                        </span>
                    ) : null}
                </h2>
                <h2 onClick={() => sortByHandler("active")}>
                    Active
                    {filters.sortingParams.key === "active" ? (
                        <span>
                            {filters.sortingParams.direction ? (
                                <i className="bi bi-caret-up-fill"></i>
                            ) : (
                                <i className="bi bi-caret-down-fill"></i>
                            )}
                        </span>
                    ) : null}
                </h2>
                <h2 onClick={() => sortByHandler("type")}>
                    Type
                    {filters.sortingParams.key === "type" ? (
                        <span>
                            {filters.sortingParams.direction ? (
                                <i className="bi bi-caret-up-fill"></i>
                            ) : (
                                <i className="bi bi-caret-down-fill"></i>
                            )}
                        </span>
                    ) : null}
                </h2>
                <h2 onClick={() => sortByHandler("owner")}>
                    Owner
                    {filters.sortingParams.key === "owner" ? (
                        <span>
                            {filters.sortingParams.direction ? (
                                <i className="bi bi-caret-up-fill"></i>
                            ) : (
                                <i className="bi bi-caret-down-fill"></i>
                            )}
                        </span>
                    ) : null}
                </h2>
                <h2 onClick={() => sortByHandler("creationTime")}>
                    Created on
                    {filters.sortingParams.key === "creationTime" ? (
                        <span>
                            {filters.sortingParams.direction ? (
                                <i className="bi bi-caret-up-fill"></i>
                            ) : (
                                <i className="bi bi-caret-down-fill"></i>
                            )}
                        </span>
                    ) : null}
                </h2>
            </div>
            <ul className="user-list-wrapper__list">
                {!loadingStatus ? (
                    userList.length ? (
                        userList.map((item: any, index: number) => {
                            return (
                                <UserListRow
                                    key={`userList_${index}`}
                                    userData={{
                                        id: item.id,
                                        firstName: item.firstName,
                                        lastName: item.lastName,
                                        email: item.email,
                                        active: item.active,
                                        type: item.type,
                                        owner: item.owner,
                                        date: item.creationTime,
                                    }}
                                    goToEditHandler={(
                                        event: MouseEvent<HTMLSpanElement>
                                    ) => goToEditHandler(item.id, event)}
                                />
                            );
                        })
                    ) : (
                        <div className="empty-list">
                            <i className="bi bi-journal-bookmark"></i>
                            <p>No users found</p>
                        </div>
                    )
                ) : (
                    <div className="empty-list">
                        <div className="spinner-border" role="status"></div>
                    </div>
                )}
            </ul>
        </>
    );
}
