import { useEffect } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import logo from "../assets/logo/logo-header.svg";
import HomeTemplate from "./HomeTemplate";
import { useAuth } from "./hooks/useAuth";
import { useInput } from "./hooks/useInput";
import { useRouter } from "./hooks/useRouter";

export default function Login() {
    const { signIn, lastErrorMessage, isLogged, loadingUser } = useAuth();
    const router = useRouter();

    const email = useInput("");
    const password = useInput("");

    const loginCredentials = {
        password: password.state,
        username: email.state,
    };

    useEffect(() => {
        if (isLogged) {
            router.push("/");
        }
    }, [isLogged, router, loadingUser]);

    const submitHandler = async (event: any) => {
        event.preventDefault();
        await signIn!(loginCredentials);
    };

    return (
        <HomeTemplate styleClass="login-container">
            <Row className="justify-content-center w-100">
                <Col md={6}>
                    <section className="login-wrapper w-100 d-flex">
                        <div className="login-wrapper__logo">
                            <img src={logo} alt="logo" />
                        </div>
                        <h1 className="login-wrapper__header">
                            Dashboard Login
                        </h1>
                        {lastErrorMessage && (
                            <Alert variant="danger" className="mt-4">
                                {lastErrorMessage}
                            </Alert>
                        )}
                        <Form
                            onSubmit={submitHandler}
                            className="login-wrapper__form"
                        >
                            <Form.Group
                                className="mb-3 login-wrapper__form__input"
                                controlId="formGroupEmail"
                            >
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    autoFocus
                                    type="email"
                                    placeholder="Enter email"
                                    value={email.state}
                                    onChange={email.onChangeHandler}
                                />
                            </Form.Group>
                            <Form.Group
                                className="mb-3 login-wrapper__form__input"
                                controlId="formGroupPassword"
                            >
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    value={password.state}
                                    onChange={password.onChangeHandler}
                                />
                            </Form.Group>
                            <Button
                                disabled={loadingUser}
                                variant="primary"
                                type="submit"
                                className="login-wrapper__form__button"
                            >
                                Sign in
                            </Button>
                        </Form>
                    </section>
                </Col>
            </Row>
        </HomeTemplate>
    );
}
