import { Container } from "react-bootstrap";

type HomeTemplateProps = {
    children?: JSX.Element | JSX.Element[];
    styleClass?: string;
};

export default function HomeTemplate({
    children,
    styleClass,
}: HomeTemplateProps) {
    return (
        <Container fluid className={`dashboard-wrapper ${styleClass}`}>
            {children}
        </Container>
    );
}
