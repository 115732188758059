import DashboardContent from "../components/DashboardContent";
import Header from "../components/Header";
import HomeTemplate from "../components/HomeTemplate";
import { useRequireAuth } from "../components/hooks/useRequiredAuth";

export default function Home() {
    useRequireAuth("/login");

    return (
        <HomeTemplate>
            <Header />
            <DashboardContent />
        </HomeTemplate>
    );
}
