import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import {
    Alert,
    Button,
    Col,
    Container,
    Form,
    FormControl,
    Row,
} from "react-bootstrap";
import { useInput } from "./hooks/useInput";
import { useValidation } from "./hooks/useValidation";
import labelDictionary from "../labelDictionary.json";
import { CenteredModal } from "./modals/CenteredModal";
import moment from "moment";
import { CountriesSelect } from "./CountriesSelect";

type UserFormRegistrationProps = {
    updateUserFunction: Function | null;
    incomingUserData: any;
    mutate: Function;
    checkUsernameDuplication: Function;
    goback: (
        value: "Users" | "Pending-Users" | "Add-Users" | "Edit-User"
    ) => void;
    selectUser: Function;
    deleteUser: Function;
};

const { AccountType, Profile, Owner, ContractType, PortfolioNumber } =
    labelDictionary;

export default function EditUserForm({
    incomingUserData,
    updateUserFunction,
    goback,
    mutate,
    checkUsernameDuplication,
    selectUser,
    deleteUser,
}: UserFormRegistrationProps) {
    const [showModal, setShowModal] = useState(false);
    const [isValid, setIsValid] = useState({ isFormValid: false, message: "" });
    const data = incomingUserData[0];
    const [activeUser, setActiveUser] = useState<boolean>(data.active);
    const [startValidation, setStartValidation] = useState(false);
    const email = useInput(data.email);
    const firstName = useInput(data.firstName);
    const lastName = useInput(data.lastName);
    const accountType = useInput<HTMLSelectElement>(data.type);
    const profile = useInput<HTMLSelectElement>(data.profile);
    const username = useInput(data.userName);
    const portfolioNumber = useInput<HTMLSelectElement>(data.productMax);
    const country = useInput(data.country);
    const firm = useInput(data.firm);
    const owner = useInput<HTMLSelectElement>(data.owner);
    const contractType = useInput<HTMLSelectElement>(data.contractType);
    const billingDate = useInput(data.billingStart);
    const conversionDate = useInput(data.conversionDate);

    const radioChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value === "true" ? true : false;
        setActiveUser(value);
    };

    const user = {
        id: data.id,
        firstName: firstName.state.trim(),
        lastName: lastName.state.trim(),
        email: email.state.trim(),
        userName: username.state.trim(),
        role: "STANDARD",
        profile: profile.state,
        type: accountType.state.toUpperCase(),
        firm: firm.state.trim(),
        creationTime: data.creationTime,
        active: activeUser,
        country: country.state.toUpperCase(),
        products: "all",
        contractType: contractType.state,
        productMax: parseInt(portfolioNumber.state),
        billingStart: billingDate.state,
        conversionDate: conversionDate.state,
        owner: owner.state,
        stripeId: null,
    };

    const { state, isFormValid } = useValidation({
        email: email.state,
        firstName: firstName.state,
        lastName: lastName.state,
        accountType: accountType.state,
        profile: profile.state,
        username: user.userName,
        portfolioNumber: portfolioNumber.state,
        country: country.state,
        firm: firm.state,
        owner: owner.state,
        contractType: contractType.state,
    });

    const validationState = state;

    useEffect(() => {
        const incorrectFormEntriesErrorType = {
            isFormValid: false,
            message: "Incorrect form entries",
        };

        const validFormType = {
            isFormValid: true,
            message: "",
        };

        const userExistErrorType = {
            isFormValid: false,
            message: "A user with this username already exist",
        };

        if (isFormValid && !checkUsernameDuplication(user.userName, user.id)) {
            setIsValid(validFormType);
        } else if (checkUsernameDuplication(user.userName, user.id)) {
            setIsValid(userExistErrorType);
        } else {
            setIsValid(incorrectFormEntriesErrorType);
        }
    }, [checkUsernameDuplication, user.userName, user.id, isFormValid]);

    const goBackHandler = () => {
        goback("Users");
    };

    const submitHandler = async (event: FormEvent) => {
        event.preventDefault();
        setStartValidation(true);
        if (isValid.isFormValid) {
            await updateUserFunction!(user);
            mutate();
            setStartValidation(false);
            goBackHandler();
        }
    };

    const closeModalHandler = () => {
        setShowModal(false);
    };

    const openModalHandler = () => {
        setShowModal(true);
        selectUser(data.id);
    };

    const confirmAndDeleteUser = async () => {
        await deleteUser();
        mutate();
        closeModalHandler();
        goBackHandler();
    };

    return (
        <section className="form-wrapper">
            <CenteredModal
                show={showModal}
                onHide={closeModalHandler}
                deleteUser={confirmAndDeleteUser}
            />
            <h1 className="form-wrapper__header">{`Edit ${data.userName}`}</h1>
            {!isValid.isFormValid && (
                <Alert variant="danger" className="mt-4">
                    {isValid.message}
                </Alert>
            )}
            <Form onSubmit={submitHandler} className="form-wrapper__form mb-4">
                <Container fluid>
                    <Row>
                        <Col md={2}>
                            <p className="form-wrapper__form__label">
                                Set User active?
                            </p>
                            <Form.Check
                                inline
                                name="active-user"
                                type="radio"
                                label="Yes"
                                value={"true"}
                                onChange={radioChangeHandler}
                                checked={activeUser && true}
                            />
                            <Form.Check
                                inline
                                name="active-user"
                                type="radio"
                                label="no"
                                value={"false"}
                                onChange={radioChangeHandler}
                                checked={!activeUser && true}
                            />
                        </Col>
                        <Col md={3}>
                            <p className="form-wrapper__form__label">
                                Account Type
                            </p>
                            <Form.Select
                                className={
                                    startValidation
                                        ? validationState.accountType.isValid
                                            ? "valid-field"
                                            : "invalid-field"
                                        : ""
                                }
                                aria-label="Default select example"
                                value={accountType.state}
                                onChange={accountType.onChangeHandler}
                            >
                                <option>Choose an option</option>
                                <option value={AccountType.internal}>
                                    Internal
                                </option>
                                <option value={AccountType.subscriber}>
                                    Subscriber
                                </option>
                                <option value={AccountType.partner}>
                                    Partner
                                </option>
                                <option value={AccountType.trial}>Trial</option>
                            </Form.Select>
                            {startValidation
                                ? !validationState.accountType.isValid && (
                                      <p>
                                          {validationState.accountType.message}
                                      </p>
                                  )
                                : null}
                        </Col>
                        <Col md={3}>
                            <p className="form-wrapper__form__label">Owner</p>
                            <Form.Select
                                className={
                                    startValidation
                                        ? validationState.owner.isValid
                                            ? "valid-field"
                                            : "invalid-field"
                                        : ""
                                }
                                aria-label="Default select example"
                                value={owner.state}
                                onChange={owner.onChangeHandler}
                            >
                                <option>Choose an option</option>
                                <option value={Owner.coulterJhon}>
                                    Coulter John
                                </option>
                                <option value={Owner.jordanRoland}>
                                    Jordan Roland
                                </option>
                                <option value={Owner.marinelliAntonio}>
                                    Marinelli Antonio
                                </option>
                                <option value={Owner.nathwaniShiv}>
                                    Nathwani Shiv
                                </option>
                                <option value={Owner.pellegrinelliMassimiliano}>
                                    Pellegrinelli Massimiliano
                                </option>
                                <option value={Owner.pellegrinelliGuido}>
                                    Pellegrinelli Guido
                                </option>
                                <option value={Owner.andreaMontanaro}>
                                    Montanaro Andrea
                                </option>
                                <option value={Owner.admin}>Admin</option>
                            </Form.Select>
                            {startValidation
                                ? !validationState.owner.isValid && (
                                      <p>{validationState.owner.message}</p>
                                  )
                                : null}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={5}>
                            <p className="form-wrapper__form__label">
                                First Name
                            </p>
                            <FormControl
                                className={`form-wrapper__form__input ${
                                    startValidation
                                        ? validationState.firstName.isValid
                                            ? "valid-field"
                                            : "invalid-field"
                                        : ""
                                }`}
                                placeholder="Inser user firstname"
                                aria-label="first-name"
                                aria-describedby="first-name"
                                value={firstName.state}
                                onChange={firstName.onChangeHandler}
                            />
                            {startValidation
                                ? !validationState.firstName.isValid && (
                                      <p>{validationState.firstName.message}</p>
                                  )
                                : null}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={5}>
                            <p className="form-wrapper__form__label">
                                Last Name
                            </p>
                            <FormControl
                                className={`form-wrapper__form__input ${
                                    startValidation
                                        ? validationState.lastName.isValid
                                            ? "valid-field"
                                            : "invalid-field"
                                        : ""
                                }`}
                                placeholder="Insert user lastname"
                                aria-label="last Name"
                                aria-describedby="last-name"
                                value={lastName.state}
                                onChange={lastName.onChangeHandler}
                            />
                            {startValidation
                                ? !validationState.lastName.isValid && (
                                      <p>{validationState.lastName.message}</p>
                                  )
                                : null}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={5}>
                            <p className="form-wrapper__form__label">Email</p>
                            <FormControl
                                className={`form-wrapper__form__input ${
                                    startValidation
                                        ? validationState.email.isValid
                                            ? "valid-field"
                                            : "invalid-field"
                                        : ""
                                }`}
                                placeholder="This email will be used as user username"
                                aria-label="email"
                                aria-describedby="email"
                                type="email"
                                value={email.state}
                                onChange={email.onChangeHandler}
                            />
                            {startValidation
                                ? !validationState.email.isValid && (
                                      <p>{validationState.email.message}</p>
                                  )
                                : null}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={2}>
                            <CountriesSelect
                                value={country.state}
                                onChange={country.onChangeHandler}
                                startValidation={startValidation}
                                validationState={validationState}
                            />
                        </Col>
                        <Col md={4}>
                            <p className="form-wrapper__form__label">Firm</p>
                            <FormControl
                                className={`form-wrapper__form__input ${
                                    startValidation
                                        ? validationState.firm.isValid
                                            ? "valid-field"
                                            : "invalid-field"
                                        : ""
                                }`}
                                placeholder="Insert the user firm"
                                aria-label="Firm"
                                aria-describedby="firm"
                                value={firm.state}
                                onChange={firm.onChangeHandler}
                            />
                            {startValidation
                                ? !validationState.firm.isValid && (
                                      <p>{validationState.firm.message}</p>
                                  )
                                : null}
                        </Col>
                        <Col md={3}>
                            <p className="form-wrapper__form__label">Profile</p>
                            <Form.Select
                                className={
                                    startValidation
                                        ? validationState.profile.isValid
                                            ? "valid-field"
                                            : "invalid-field"
                                        : ""
                                }
                                aria-label="Default select example"
                                value={profile.state}
                                onChange={profile.onChangeHandler}
                            >
                                <option>Choose an option</option>
                                <option value={Profile.portfolioManager}>
                                    Portfolio Manager
                                </option>
                                <option value={Profile.fundManager}>
                                    Fund Manager
                                </option>
                                <option value={Profile.wealthManager}>
                                    Wealth Manager
                                </option>
                                <option value={Profile.financialAdvisor}>
                                    Financial Advisor
                                </option>
                                <option value={Profile.hedgeFundManager}>
                                    Hedge Fund Manager
                                </option>
                                <option value={Profile.familyOffice}>
                                    Family office
                                </option>
                                <option value={Profile.broker}>Broker</option>
                                <option value={Profile.others}>Others</option>
                            </Form.Select>
                            {startValidation
                                ? !validationState.profile.isValid && (
                                      <p>{validationState.profile.message}</p>
                                  )
                                : null}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={5}>
                            <p className="form-wrapper__form__label">
                                Contact email
                            </p>
                            <FormControl
                                className={`form-wrapper__form__input ${
                                    startValidation
                                        ? validationState.userName.isValid
                                            ? "valid-field"
                                            : "invalid-field"
                                        : ""
                                }`}
                                aria-label="Username"
                                aria-describedby="Username"
                                value={username.state}
                                onChange={username.onChangeHandler}
                            />
                            {startValidation
                                ? !validationState.userName.isValid && (
                                      <p>{validationState.userName.message}</p>
                                  )
                                : null}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={3}>
                            <p className="form-wrapper__form__label">
                                Conversion date
                            </p>
                            <FormControl
                                type="date"
                                className="form-wrapper__form__input"
                                aria-label="conversion-date"
                                aria-describedby="conversion-date"
                                value={conversionDate.state}
                                onChange={conversionDate.onChangeHandler}
                            />
                        </Col>
                        <Col md={3}>
                            <p className="form-wrapper__form__label">
                                Billing start
                            </p>
                            <FormControl
                                className="form-wrapper__form__input"
                                aria-label="billing-start"
                                aria-describedby="billing-start"
                                type="date"
                                value={billingDate.state}
                                onChange={billingDate.onChangeHandler}
                            />
                        </Col>
                        <Col md={3}>
                            <p className="form-wrapper__form__label">
                                Contract Type
                            </p>
                            <Form.Select
                                className={
                                    startValidation
                                        ? validationState.contractType.isValid
                                            ? "valid-field"
                                            : "invalid-field"
                                        : ""
                                }
                                aria-label="Default select example"
                                value={contractType.state}
                                onChange={contractType.onChangeHandler}
                            >
                                <option>Choose an option</option>
                                <option value={ContractType.performance}>
                                    Performance
                                </option>
                                <option value={ContractType.fixed}>
                                    Fixed
                                </option>
                            </Form.Select>
                            {startValidation
                                ? !validationState.contractType.isValid && (
                                      <p>
                                          {validationState.contractType.message}
                                      </p>
                                  )
                                : null}
                        </Col>
                        <Col md={2}>
                            <p className="form-wrapper__form__label">
                                Number of portfolios
                            </p>
                            <Form.Select
                                className={
                                    startValidation
                                        ? validationState.portfolios.isValid
                                            ? "valid-field"
                                            : "invalid-field"
                                        : ""
                                }
                                aria-label="Default select example"
                                value={portfolioNumber.state}
                                onChange={portfolioNumber.onChangeHandler}
                            >
                                <option>Choose an option</option>
                                <option value={PortfolioNumber.all}>All</option>
                                <option value={PortfolioNumber.one}>1</option>
                                <option value={PortfolioNumber.three}>3</option>
                                <option value={PortfolioNumber.ten}>10</option>
                            </Form.Select>
                            {startValidation
                                ? !validationState.portfolios.isValid && (
                                      <p>
                                          {validationState.portfolios.message}
                                      </p>
                                  )
                                : null}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={3}>
                            <p className="form-wrapper__form__label">
                                Creation Date
                            </p>
                            <FormControl
                                className="form-wrapper__form__input"
                                aria-label="billing-start"
                                aria-describedby="billing-start"
                                type="text"
                                value={moment(data.creationTime).format("LL")}
                                disabled
                            />
                        </Col>
                        <Col md={3}>
                            <p className="form-wrapper__form__label">
                                Stripe Id
                            </p>
                            <FormControl
                                className="form-wrapper__form__input"
                                aria-label="billing-start"
                                aria-describedby="billing-start"
                                type="text"
                                value={data?.stripeId ?? "No info"}
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={12}>
                            <Button
                                variant="primary"
                                type="submit"
                                className="form-button"
                            >
                                Save User
                            </Button>
                            <Button
                                variant="danger"
                                type="button"
                                className="form-button"
                                onClick={openModalHandler}
                            >
                                Delete User
                            </Button>
                            <Button
                                variant="secondary"
                                type="button"
                                className="form-button"
                                onClick={goBackHandler}
                            >
                                Back
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </section>
    );
}
