import {
    PendingUserSortingObject,
    PendingUsersSortingAction,
} from "./hooks/useUserFilter";
import { UserListRow } from "./UserListRow";

type PendingUserListProps = {
    userPendingList: any;
    promoteUser: Function;
    dispatch: Function;
    sortAction: (param: string) => PendingUsersSortingAction;
    sortingState: PendingUserSortingObject;
    loadingStatus: boolean;
};

export default function PendingUsersList({
    userPendingList,
    promoteUser,
    dispatch,
    sortAction,
    sortingState,
    loadingStatus,
}: PendingUserListProps) {
    const userList = userPendingList;

    const promoteUserHandler = (id: number) => {
        promoteUser(id);
    };

    const sortByHandler = (sortingKey: string) => {
        dispatch(sortAction(sortingKey));
    };

    return (
        <>
            <div className="user-list-wrapper__header">
                <h2
                    onClick={() => {
                        sortByHandler("id");
                    }}
                >
                    Id
                    {sortingState.sortingKey === "id" ? (
                        <span>
                            {sortingState.direction ? (
                                <i className="bi bi-caret-up-fill"></i>
                            ) : (
                                <i className="bi bi-caret-down-fill"></i>
                            )}
                        </span>
                    ) : null}
                </h2>
                <h2
                    onClick={() => {
                        sortByHandler("firstName");
                    }}
                >
                    First Name
                    {sortingState.sortingKey === "firstName" ? (
                        <span>
                            {sortingState.direction ? (
                                <i className="bi bi-caret-up-fill"></i>
                            ) : (
                                <i className="bi bi-caret-down-fill"></i>
                            )}
                        </span>
                    ) : null}
                </h2>
                <h2
                    onClick={() => {
                        sortByHandler("lastName");
                    }}
                >
                    Last Name
                    {sortingState.sortingKey === "lastName" ? (
                        <span>
                            {sortingState.direction ? (
                                <i className="bi bi-caret-up-fill"></i>
                            ) : (
                                <i className="bi bi-caret-down-fill"></i>
                            )}
                        </span>
                    ) : null}
                </h2>
                <h2
                    onClick={() => {
                        sortByHandler("email");
                    }}
                >
                    Email
                    {sortingState.sortingKey === "email" ? (
                        <span>
                            {sortingState.direction ? (
                                <i className="bi bi-caret-up-fill"></i>
                            ) : (
                                <i className="bi bi-caret-down-fill"></i>
                            )}
                        </span>
                    ) : null}
                </h2>
                <h2
                    onClick={() => {
                        sortByHandler("creationTime");
                    }}
                >
                    Created on
                    {sortingState.sortingKey === "creationTime" ? (
                        <span>
                            {sortingState.direction ? (
                                <i className="bi bi-caret-up-fill"></i>
                            ) : (
                                <i className="bi bi-caret-down-fill"></i>
                            )}
                        </span>
                    ) : null}
                </h2>
            </div>
            <ul className="user-list-wrapper__list">
                {!loadingStatus ? (
                    userList.length ? (
                        userList.map((item: any, index: number) => {
                            return (
                                <UserListRow
                                    key={`key__${item.id}`}
                                    userData={{
                                        id: item.id,
                                        firstName: item.firstName,
                                        lastName: item.lastName,
                                        email: item.email,
                                        date: item.creationTime,
                                    }}
                                    goToEditHandler={() =>
                                        promoteUserHandler(item.id)
                                    }
                                />
                            );
                        })
                    ) : (
                        <div className="empty-list">
                            <i className="bi bi-journal-bookmark"></i>
                            <p>No pending users found</p>
                        </div>
                    )
                ) : (
                    <div className="empty-list">
                        <div className="spinner-border" role="status"></div>
                    </div>
                )}
            </ul>
        </>
    );
}
