import { Alert, Button, Col, Row } from "react-bootstrap";
import logo from "../assets/logo/logo-header.svg";
import { useAuth } from "./hooks/useAuth";

export default function Header() {
    const { lastErrorMessage, signOut } = useAuth();

    const handleLogout = () => {
        signOut!();
    };

    return (
        <Row>
            <Col>
                <header className="dashboard-header">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <img src={logo} alt="Logo Trendrating" />
                        {lastErrorMessage && (
                            <Alert variant="danger" className="mt-4">
                                {lastErrorMessage}
                            </Alert>
                        )}
                        <Button
                            variant="outline-primary"
                            type="button"
                            className="form-button"
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    </div>
                </header>
            </Col>
        </Row>
    );
}
