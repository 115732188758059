import { useEffect } from "react";
import { useImmerReducer } from "use-immer";

type FormFields = {
    email: string;
    firstName: string;
    lastName: string;
    accountType: string;
    profile: string;
    username: string;
    portfolioNumber: string;
    country: string;
    firm: string;
    owner: string;
    contractType: string;
};

type ActionType = {
    type: string;
};

type InitReducerState = {
    accountType: { isValid: boolean; message: string };
    owner: { isValid: boolean; message: string };
    firstName: { isValid: boolean; message: string };
    lastName: { isValid: boolean; message: string };
    email: { isValid: boolean; message: string };
    country: { isValid: boolean; message: string };
    firm: { isValid: boolean; message: string };
    profile: { isValid: boolean; message: string };
    userName: { isValid: boolean; message: string };
    contractType: { isValid: boolean; message: string };
    portfolios: { isValid: boolean; message: string };
};

export const useValidation = (user: FormFields) => {
    const VALIDATE_TYPE = "VALIDATE_TYPE";
    const VALIDATE_OWNER = "VALIDATE_OWNER";
    const VALIDATE_FIRSTNAME = "VALIDATE_FIRSTNAME";
    const VALIDATE_LASTNAME = "VALIDATE_LASTNAME";
    const VALIDATE_EMAIL = "VALIDATE_EMAIL";
    const VALIDATE_COUNTRY = "VALIDATE_COUNTRY";
    const VALIDATE_FIRM = "VALIDATE_FIRM";
    const VALIDATE_PROFILE = "VALIDATE_PROFILE";
    const VALIDATE_USERNAME = "VALIDATE_USERNAME";
    const VALIDATE_CONTRACT_TYPE = "VALIDATE_CONTRACT_TYPE";
    const VALIDATE_PORTFOLIOS = "VALIDATE_PORTFOLIOS";

    const initialState = {
        accountType: { isValid: false, message: "" },
        owner: { isValid: false, message: "" },
        firstName: { isValid: false, message: "" },
        lastName: { isValid: false, message: "" },
        email: { isValid: false, message: "" },
        country: { isValid: false, message: "" },
        firm: { isValid: false, message: "" },
        profile: { isValid: false, message: "" },
        userName: { isValid: false, message: "" },
        contractType: { isValid: false, message: "" },
        portfolios: { isValid: false, message: "" },
    };

    const reducer = (draft: InitReducerState, action: ActionType) => {
        switch (action.type) {
            case VALIDATE_TYPE:
                if (
                    user.accountType.length &&
                    user.accountType !== "Choose an option"
                ) {
                    draft.accountType = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.accountType = {
                        isValid: false,
                        message: "This field is required",
                    };
                }
                break;

            case VALIDATE_OWNER:
                if (user.owner && user.owner !== "Choose an option") {
                    draft.owner = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.owner = {
                        isValid: false,
                        message: "This field is required",
                    };
                }
                break;

            case VALIDATE_FIRSTNAME:
                if (user.firstName.length) {
                    draft.firstName = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.firstName = {
                        isValid: false,
                        message: "This field is required",
                    };
                }
                break;

            case VALIDATE_LASTNAME:
                if (user.lastName.length) {
                    draft.lastName = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.lastName = {
                        isValid: false,
                        message: "This field is required",
                    };
                }
                break;

            case VALIDATE_EMAIL:
                if (user.email.length) {
                    draft.email = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.email = {
                        isValid: false,
                        message: "This field is required",
                    };
                }
                break;

            case VALIDATE_COUNTRY:
                if (
                    user.country.length &&
                    user.country !== "Choose a country"
                ) {
                    draft.country = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.country = {
                        isValid: false,
                        message: "This field is required",
                    };
                }
                break;

            case VALIDATE_FIRM:
                if (user.firm.length) {
                    draft.firm = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.firm = {
                        isValid: false,
                        message: "This field is required",
                    };
                }
                break;

            case VALIDATE_PROFILE:
                if (
                    user.profile.length &&
                    user.profile !== "Choose an option"
                ) {
                    draft.profile = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.profile = {
                        isValid: false,
                        message: "This field is required",
                    };
                }
                break;

            case VALIDATE_USERNAME:
                if (user.username.length) {
                    draft.userName = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.userName = {
                        isValid: false,
                        message: "This field is required",
                    };
                }
                break;

            case VALIDATE_CONTRACT_TYPE:
                if (
                    user.contractType.length &&
                    user.contractType !== "Choose an option"
                ) {
                    draft.contractType = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.contractType = {
                        isValid: false,
                        message: "This field is required",
                    };
                }
                break;

            case VALIDATE_PORTFOLIOS:
                if (
                    JSON.stringify(user.portfolioNumber).length &&
                    !isNaN(parseInt(user.portfolioNumber))
                ) {
                    draft.portfolios = {
                        isValid: true,
                        message: "",
                    };
                } else {
                    draft.portfolios = {
                        isValid: false,
                        message: "This field is required",
                    };
                }
                break;

            default:
                return draft;
        }
    };

    const [state, dispatch] = useImmerReducer(reducer, initialState);

    useEffect(() => {
        dispatch({ type: VALIDATE_EMAIL });
    }, [dispatch, user.email]);

    useEffect(() => {
        dispatch({ type: VALIDATE_TYPE });
    }, [dispatch, user.accountType]);

    useEffect(() => {
        dispatch({ type: VALIDATE_OWNER });
    }, [dispatch, user.owner]);

    useEffect(() => {
        dispatch({ type: VALIDATE_FIRSTNAME });
    }, [dispatch, user.firstName]);

    useEffect(() => {
        dispatch({ type: VALIDATE_LASTNAME });
    }, [dispatch, user.lastName]);

    useEffect(() => {
        dispatch({ type: VALIDATE_COUNTRY });
    }, [dispatch, user.country]);

    useEffect(() => {
        dispatch({ type: VALIDATE_FIRM });
    }, [dispatch, user.firm]);

    useEffect(() => {
        dispatch({ type: VALIDATE_PROFILE });
    }, [dispatch, user.profile]);

    useEffect(() => {
        dispatch({ type: VALIDATE_USERNAME });
    }, [dispatch, user.username]);

    useEffect(() => {
        dispatch({ type: VALIDATE_CONTRACT_TYPE });
    }, [dispatch, user.contractType]);

    useEffect(() => {
        dispatch({ type: VALIDATE_PORTFOLIOS });
    }, [dispatch, user.portfolioNumber]);

    const fields = [
        state.firstName,
        state.lastName,
        state.accountType,
        state.owner,
        state.email,
        state.country,
        state.firm,
        state.profile,
        state.userName,
        state.contractType,
        state.portfolios,
    ];

    const invalidFields = fields.find(
        (item: { isValid: boolean; message: string }) => item.isValid === false
    );

    const isFormValid = invalidFields ? false : true;

    return { state, isFormValid };
};
