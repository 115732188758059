import { Filter } from "./components/hooks/useUserFilter";

export const createFilter = (filters: Filter) => {
    let filterArray: any = [];
    let customFilter = {};
    let customSortingParameters = {
        dimension: "",
        rev: false,
    };

    Object.entries(filters).forEach(([key, value]) => {
        if (typeof value === "string" && value.length) {
            if (key !== "active" && key !== "portfolios") {
                customFilter = {
                    dimension: key,
                    segments: [value],
                };
                filterArray.push(customFilter);
            } else if (key === "active") {
                const activeValue = value === "true" ? true : false;
                customFilter = {
                    dimension: key,
                    segments: [activeValue],
                };
                filterArray.push(customFilter);
            } else if (key === "portfolios") {
                customFilter = {
                    dimension: "productMax",
                    segments: [parseInt(value, 10)],
                };
                filterArray.push(customFilter);
            }
        } else if (typeof value !== "string") {
            customSortingParameters = {
                dimension: value.key,
                rev: value.direction,
            };
        } else {
            filterArray = [...filterArray];
        }
    });

    return {
        filters: filterArray,
        sort: customSortingParameters,
    };
};
