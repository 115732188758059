import { createContext, useContext, useEffect, useState } from "react";
import AuthServices, {
    CreateUserFields,
    SignInCredentials,
} from "../../services/AuthServices";

type Auth =
    | {
          signIn: Function | null;
          signOut: Function | null;
          user: any;
          createUser: Function | null;
          updateUser: Function | null;
          userCreated: any;
          isLogged: boolean;
          lastErrorMessage: string | null;
          loadingUser: boolean;
      }
    | undefined;

const AuthContext = createContext<Auth>(undefined);

export function ProvideAuth({ children }: any) {
    let auth = useProvideAuth();
    if (auth === null) {
        auth = {
            signIn: null,
            signOut: null,
            user: null,
            createUser: null,
            userCreated: null,
            isLogged: false,
            lastErrorMessage: null,
            updateUser: null,
            loadingUser: false,
        };
    }
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within a ProvideAuth");
    }
    return context;
};

export const useProvideAuth = (): Auth => {
    const [user, setUser] = useState<any>();
    const [loadingUser, setLoadingUser] = useState(false);
    const [userCreated, setUserCreated] = useState<any>();
    const [isLogged, setIsLogged] = useState<any>(false);
    const [lastErrorMessage, setLastErrorMessage] = useState("");

    // Note: sessionInfo does not have data.data.user, only data.data
    useEffect(() => {
        AuthServices.sessionCheck().then(
            (loggedUser: any) => {
                if (loggedUser?.response?.status === 401) {
                    setIsLogged(false);
                } else if (!loggedUser?.data?.error) {
                    const userType = loggedUser.data.data.type
                        ? loggedUser.data.data.type
                        : "";
                    if (userType === "INTERNAL") {
                        setIsLogged(true);
                        setUser(loggedUser);
                    } else {
                        setIsLogged(false);
                        setLastErrorMessage("You are not allow to login");
                    }
                } else {
                    setIsLogged(false);
                }
            },
            (error) => {
                setLastErrorMessage("Something goes wrong please retry later");
                setLoadingUser(false);
            }
        );
    }, []);

    const signIn = async (userCredentials: SignInCredentials) => {
        setLastErrorMessage("");
        setLoadingUser(true);
        try {
            const loggedUser: any = await AuthServices.signIn(userCredentials);

            setLoadingUser(false);

            if (!loggedUser.data.error) {
                const userType = loggedUser.data.data.user.type
                    ? loggedUser.data.data.user.type
                    : "";
                if (userType === "INTERNAL") {
                    setIsLogged(true);
                    setUser(loggedUser);
                } else {
                    setIsLogged(false);
                    setLastErrorMessage("You are not allow to login");
                }
            } else {
                setIsLogged(false);
                setLastErrorMessage(loggedUser.data.error);
            }
        } catch (error) {
            setLastErrorMessage("Something goes wrong please retry later");
            setLoadingUser(false);
        }
    };

    const signOut = async () => {
        setLastErrorMessage("");
        try {
            await AuthServices.signOut();
            setIsLogged(false);
        } catch (error) {
            setLastErrorMessage("Something goes wrong please retry later");
        }
    };

    const createUser = (user: CreateUserFields) => {
        const registeredUser = AuthServices.createUser(user);
        setUserCreated(registeredUser);
        return registeredUser;
    };

    const updateUser = (user: any) => {
        return AuthServices.updateUser(user);
    };

    return {
        user,
        signIn,
        signOut,
        createUser,
        userCreated,
        isLogged,
        lastErrorMessage,
        updateUser,
        loadingUser,
    };
};
