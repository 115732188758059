import axios from "axios";

export type SignInCredentials = {
    username: string;
    password: string;
};

export type CreateUserFields = {
    active: boolean;
    email: string;
    firstName: string;
    lastName: string;
    type: string;
    role: string;
    profile: string;
    userName: string;
    productMax: number;
    billingStart: string;
    conversionDate: string;
    owner: string;
    password: string;
    country: string;
    firm: string;
};

type Filter = {
    filters: any[];
    sort?: {
        dimension: string;
        rev: boolean;
    };
};

export default class AuthServices {
    static LOGIN_ENDPOINT = "/trendrating-rest/api/useronline/login";
    static LOGOUT_ENDPOINT = "/trendrating-rest/api/useronline/logout";
    static CREATE_USER_ENDPOINT = "/trendrating-rest/api/useronline/register";
    static GET_ALL_USERS_ENDPOINT = "/trendrating-rest/api/useronline/search";
    static DELETE_USER = "/trendrating-rest/api/useronline/delete?id=";
    static SESSION_CHECK = "/trendrating-rest/api/useronline/sessionInfo";
    static UPDATE_USER_ENDPOINT =
        "/trendrating-rest/api/useronline/updateFields";
    static GET_ALL_PENDING_USERS_ENDPOINT =
        "/trendrating-rest/api/pendingRegistration/search";
    static DELETE_USER_PENDING =
        "/trendrating-rest/api/pendingRegistration/delete?id=";

    static OPTIONS = {
        withCredentials: true,
    };

    static sessionCheck = async () => {
        try {
            const res = await axios.get(this.SESSION_CHECK, this.OPTIONS);

            return res;
        } catch (error) {
            return error;
        }
    };

    static signIn = async ({ username, password }: SignInCredentials) => {
        try {
            const res = await axios.post(
                this.LOGIN_ENDPOINT,
                {
                    password: password,
                    userName: username,
                },
                this.OPTIONS
            );

            return res;
        } catch (error) {
            return error;
        }
    };

    static signOut = async () => {
        try {
            const res = await axios.get(this.LOGOUT_ENDPOINT, this.OPTIONS);

            return res;
        } catch (error) {
            return error;
        }
    };

    static createUser = async (user: CreateUserFields) => {
        const res = await axios.post(
            this.CREATE_USER_ENDPOINT,
            user,
            this.OPTIONS
        );
        return res;
    };

    static getAllUserList = async (filters: Filter) => {
        const res = await axios.post(this.GET_ALL_USERS_ENDPOINT, filters);

        return res;
    };

    static deleteUser = async (id: number | undefined) => {
        const url = this.DELETE_USER + id;
        const res = await axios.get(url, this.OPTIONS);
        return res;
    };

    static updateUser = async (userAttributes: any) => {
        const res = await axios.post(
            this.UPDATE_USER_ENDPOINT,
            userAttributes,
            this.OPTIONS
        );
        return res;
    };

    static getPendingUsers = async (sortingParameter: {
        sort: {
            dimension: string;
            rev: boolean;
        };
    }) => {
        const res = await axios.post(
            this.GET_ALL_PENDING_USERS_ENDPOINT,
            sortingParameter,
            this.OPTIONS
        );

        return res;
    };

    static deleteUserFromPending = async (id: number | undefined) => {
        const url = this.DELETE_USER_PENDING + id;
        const res = await axios.get(url, this.OPTIONS);
        return res;
    };
}
