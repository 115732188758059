import { useImmerReducer } from "use-immer";

export type Filter = {
    type: string;
    profile: string;
    contractType: string;
    active: string;
    owner: string;
    portfolios: string;
    sortingParams: {
        key: string;
        direction: boolean;
    };
};

export type UserFilterReducerActionType =
    | {
          type: "SET_ACCOUNT_TYPE_FILTER";
          payload: string;
      }
    | {
          type: "SET_PROFILE_FILTER";
          payload: string;
      }
    | {
          type: "SET_CONTRACT_TYPE_FILTER";
          payload: string;
      }
    | {
          type: "SET_ACTIVE_FILTER";
          payload: string;
      }
    | {
          type: "SET_OWNER_FILTER";
          payload: string;
      }
    | {
          type: "SET_PORTFOLIOS_FILTER";
          payload: string;
      }
    | {
          type: "SET_SORT_BY";
          payload: string;
      };

export const useUserFilter = () => {
    const filterValue = {
        type: "",
        profile: "",
        contractType: "",
        active: "",
        owner: "",
        portfolios: "",
        sortingParams: {
            key: "id",
            direction: false,
        },
    };

    const actions = {
        setAccountType: (filter: string): UserFilterReducerActionType => {
            return {
                type: SET_ACCOUNT_TYPE_FILTER,
                payload: filter.toUpperCase(),
            };
        },
        setProfile: (filter: string): UserFilterReducerActionType => {
            return { type: SET_PROFILE_FILTER, payload: filter };
        },
        setContractType: (filter: string): UserFilterReducerActionType => {
            return { type: SET_CONTRACT_TYPE_FILTER, payload: filter };
        },
        setActive: (filter: string): UserFilterReducerActionType => {
            return { type: SET_ACTIVE_FILTER, payload: filter };
        },
        setOwner: (filter: string): UserFilterReducerActionType => {
            return { type: SET_OWNER_FILTER, payload: filter };
        },
        setPortfolios: (filter: string): UserFilterReducerActionType => {
            return { type: SET_PORTFOLIOS_FILTER, payload: filter };
        },
        setSortBy: (sortKey: string): UserFilterReducerActionType => {
            return { type: SET_SORT_BY, payload: sortKey };
        },
    };

    const SET_ACCOUNT_TYPE_FILTER = "SET_ACCOUNT_TYPE_FILTER";
    const SET_PROFILE_FILTER = "SET_PROFILE_FILTER";
    const SET_CONTRACT_TYPE_FILTER = "SET_CONTRACT_TYPE_FILTER";
    const SET_ACTIVE_FILTER = "SET_ACTIVE_FILTER";
    const SET_OWNER_FILTER = "SET_OWNER_FILTER";
    const SET_PORTFOLIOS_FILTER = "SET_PORTFOLIOS_FILTER";
    const SET_SORT_BY = "SET_SORT_BY";

    const reducer = (draft: Filter, action: UserFilterReducerActionType) => {
        switch (action.type) {
            case SET_ACCOUNT_TYPE_FILTER:
                draft.type = action.payload;
                break;
            case SET_PROFILE_FILTER:
                draft.profile = action.payload;
                break;
            case SET_CONTRACT_TYPE_FILTER:
                draft.contractType = action.payload;
                break;
            case SET_ACTIVE_FILTER:
                draft.active = action.payload;
                break;
            case SET_OWNER_FILTER:
                draft.owner = action.payload;
                break;
            case SET_PORTFOLIOS_FILTER:
                draft.portfolios = action.payload;
                break;
            case SET_SORT_BY:
                if (draft.sortingParams.key === action.payload) {
                    draft.sortingParams = {
                        key: draft.sortingParams.key,
                        direction: !draft.sortingParams.direction,
                    };
                } else {
                    draft.sortingParams = {
                        key: action.payload,
                        direction: false,
                    };
                }
                break;
            default:
                return draft;
        }
    };

    const [filters, dispatch] = useImmerReducer(reducer, filterValue);

    return { filters, dispatch, actions };
};

export type PendingUserSortingObject = {
    sortingKey: string;
    direction: boolean;
};

export type PendingUsersSortingAction = {
    type: "SET_SORTING_PARAMETER";
    payload: string;
};

export const usePendingUsersSorting = () => {
    const sortingValues = {
        sortingKey: "id",
        direction: false,
    };

    const SET_SORTING_PARAMETER = "SET_SORTING_PARAMETER";

    const action = (sortingKey: string): PendingUsersSortingAction => {
        return {
            type: SET_SORTING_PARAMETER,
            payload: sortingKey,
        };
    };

    const reducer = (
        draft: PendingUserSortingObject,
        action: PendingUsersSortingAction
    ) => {
        switch (action.type) {
            case SET_SORTING_PARAMETER:
                if (draft.sortingKey === action.payload) {
                    return (draft = {
                        sortingKey: draft.sortingKey,
                        direction: !draft.direction,
                    });
                } else {
                    return (draft = {
                        sortingKey: action.payload,
                        direction: false,
                    });
                }
            default:
                return draft;
        }
    };

    const [sortingParameter, dispatch] = useImmerReducer(
        reducer,
        sortingValues
    );

    return { sortingParameter, dispatch, action };
};
