import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import useSWR from "swr";
import { createFilter } from "../createFilters";
import { createSortingPendingObject } from "../createSortingMethod";
import AuthServices from "../services/AuthServices";
import EditUserForm from "./EditUserForm";
import { FilterWidget } from "./FilterWidget";
import { useAuth } from "./hooks/useAuth";
import {
    Filter,
    PendingUserSortingObject,
    usePendingUsersSorting,
    useUserFilter,
} from "./hooks/useUserFilter";
import PendingUsersList from "./PendingUserList";
import SideBarWidget from "./SideBarWidget";
import UserFormRegistration from "./UserFormRegistration";
import UsersList from "./UsersList";

type SidebarActiveItem = "Users" | "Pending-Users" | "Add-Users" | "Edit-User";

export default function DashboardContent() {
    const [sidebarActiveItem, setSidebarActiveItem] =
        useState<SidebarActiveItem>("Users");
    const [selectedUserId, setSelectedUserId] = useState<undefined | number>();
    const [selectedPendingUserId, setSelectedPendingUserId] = useState<
        undefined | number
    >();

    const { filters, dispatch, actions } = useUserFilter();
    const {
        sortingParameter,
        dispatch: pendigSortingDispatch,
        action: pendingSortingAction,
    } = usePendingUsersSorting();

    const USER_VIEW = "Users";
    const PENDING_USERS = "Pending-Users";
    const ADD_USERS = "Add-Users";
    const EDIT_USER = "Edit-User";

    const fetcher = (url: string, filters: Filter) => {
        return AuthServices.getAllUserList(createFilter(filters));
    };

    const { data, mutate, isValidating } = useSWR(
        [AuthServices.GET_ALL_USERS_ENDPOINT, filters],
        fetcher
    );

    const isLoading = isValidating;

    const pendingFetcher = (
        url: string,
        sortingParameter: PendingUserSortingObject
    ) =>
        AuthServices.getPendingUsers(
            createSortingPendingObject(sortingParameter)
        );

    const {
        data: dataPending,
        mutate: mutatePending,
        isValidating: isValidationgPending,
    } = useSWR(
        [AuthServices.GET_ALL_PENDING_USERS_ENDPOINT, sortingParameter],
        pendingFetcher
    );

    const isLoadingPending = isValidationgPending;

    const userPendingList = dataPending?.data?.data?.rows ?? [];
    const userList = data?.data?.data?.rows ?? [];

    const checkIfUserExist = (username: string, id: number) => {
        if (id) {
            const duplicate = userList.filter(
                (user: { [key: string]: any }) =>
                    user.id !== id && user.userName === username
            );
            return duplicate.length ? true : false;
        } else {
            const duplicate = userList.filter(
                (user: { [key: string]: any }) => user.userName === username
            );

            return duplicate.length ? true : false;
        }
    };

    const selectedUserInfo = userList.filter(
        (item: any) => item.id === selectedUserId
    );

    const selectedPendingUserInfo = userPendingList.find(
        (item: any) => item.id === selectedPendingUserId
    );

    const handleActiveItems = (itemValue: SidebarActiveItem) => {
        setSidebarActiveItem(itemValue);
    };

    const goToEditUser = () => {
        setSidebarActiveItem(EDIT_USER);
    };

    const deleteUserFromPending = () => {
        return AuthServices.deleteUserFromPending(selectedPendingUserId);
    };

    const deleteUserById = () => {
        return AuthServices.deleteUser(selectedUserId);
    };

    const passingUserFromPendingToActive = (id: number) => {
        setSelectedPendingUserId(id);
        setSidebarActiveItem("Add-Users");
    };

    const { createUser, updateUser } = useAuth();

    const handleDashboardView = () => {
        switch (sidebarActiveItem) {
            case USER_VIEW:
                return (
                    <UsersList
                        loadingStatus={isLoading}
                        userList={userList}
                        loading={false}
                        selectUser={setSelectedUserId}
                        goToEdit={goToEditUser}
                        filterWidget={
                            <FilterWidget
                                filters={filters}
                                dispatch={dispatch}
                                actions={actions}
                            />
                        }
                        dispatch={dispatch}
                        actions={actions}
                        filters={filters}
                    />
                );
            case PENDING_USERS:
                return (
                    <PendingUsersList
                        loadingStatus={isLoadingPending}
                        userPendingList={userPendingList}
                        promoteUser={passingUserFromPendingToActive}
                        dispatch={pendigSortingDispatch}
                        sortAction={pendingSortingAction}
                        sortingState={sortingParameter}
                    />
                );
            case ADD_USERS:
                return (
                    <UserFormRegistration
                        selectUser={setSelectedPendingUserId}
                        checkIfUserExist={checkIfUserExist}
                        mutate={mutate}
                        goback={handleActiveItems}
                        createUserFunction={createUser!}
                        resetSelectedPendingUser={setSelectedPendingUserId}
                        deletePendingUser={deleteUserFromPending}
                        loadPendingUserData={selectedPendingUserInfo}
                        mutatePending={mutatePending}
                    />
                );
            case EDIT_USER:
                return (
                    <EditUserForm
                        checkUsernameDuplication={checkIfUserExist}
                        goback={handleActiveItems}
                        incomingUserData={selectedUserInfo}
                        updateUserFunction={updateUser}
                        mutate={mutate}
                        selectUser={setSelectedUserId}
                        deleteUser={deleteUserById}
                    />
                );
        }
    };

    const mainContent = handleDashboardView();

    return (
        <Container fluid>
            <Row className="justify-content-center">
                <Col md={6}>
                    <SideBarWidget
                        sidebarHandler={handleActiveItems}
                        activeItem={sidebarActiveItem}
                    />
                </Col>
            </Row>
            <Row className="justify-content-end">
                <Col md={12}>
                    <section
                        className={`user-list-wrapper ${
                            sidebarActiveItem !== "Users"
                                ? "rounded-top-left"
                                : ""
                        }`}
                    >
                        {mainContent}
                    </section>
                </Col>
            </Row>
        </Container>
    );
}
