import moment from "moment";

type UserListRowProps = {
    userData: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        active?: string;
        type?: string;
        date: string;
        owner?: string;
    };
    goToEditHandler: Function;
};

export function UserListRow({ userData, goToEditHandler }: UserListRowProps) {
    return (
        <li
            onClick={() => goToEditHandler()}
            className="user-list-wrapper__list__item"
        >
            {Object.entries(userData).map(([key, value], index) => {
                switch (key) {
                    case "id":
                        return (
                            <span
                                key={`key__${index}`}
                                className="user-list-wrapper__list__item__id"
                            >
                                {`#${value}`}
                            </span>
                        );

                    case "date":
                        return (
                            <span
                                key={`key__${index}`}
                                className="user-list-wrapper__list__item__data"
                            >
                                {moment(value).format("ll")}
                            </span>
                        );
                    case "type":
                        return (
                            <span
                                key={`key__${index}`}
                                className="user-list-wrapper__list__item__data"
                            >
                                {value.charAt(0).toUpperCase() +
                                    value.substr(1).toLowerCase()}
                            </span>
                        );
                    case "active":
                        return (
                            <span
                                key={`key__${index}`}
                                className="user-list-wrapper__list__item__data"
                            >
                                {`${value ? "true" : "false"}`}
                            </span>
                        );
                    default:
                        return (
                            <span
                                key={`key__${index}`}
                                className={`user-list-wrapper__list__item__data`}
                            >
                                {value}
                            </span>
                        );
                }
            })}
            {/* <span className="user-list-wrapper__list__item__name">
                {firstName}
            </span>
            <span className="user-list-wrapper__list__item__last-name">
                {lastName}
            </span>
            <span className="user-list-wrapper__list__item__email">
                {email}
            </span>
            <span className="user-list-wrapper__list__item__active">
                {`${active ? "true" : "false"}`}
            </span>
            <span className="user-list-wrapper__list__item__account-type">
                {type.charAt(0).toUpperCase() + type.substr(1).toLowerCase()}
            </span>
            <span className="user-list-wrapper__list__item__owner">
                {owner}
            </span>
            <span className="user-list-wrapper__list__item__creation-date">
                {moment(date).format("ll")}
            </span> */}
        </li>
    );
}
