import { MouseEventHandler } from "react";
import { Button, Modal } from "react-bootstrap";

type ModalProps = {
    show: boolean;
    onHide: MouseEventHandler<HTMLButtonElement>;
    deleteUser: MouseEventHandler<HTMLButtonElement>;
};

export function CenteredModal({ show, onHide, deleteUser }: ModalProps) {
    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Delete User
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    You are about to delete a user, this action is not
                    reversible are you sure you want to continue?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} variant="secondary">
                    Close
                </Button>
                <Button onClick={deleteUser} variant="danger">
                    Delete User
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
