type SideBarWidgetProps = {
    sidebarHandler: Function;
    activeItem:
        | "Users"
        | "Pending-Users"
        | "Add-Users"
        | "User-details"
        | "Edit-User"
        | "Pending-User-Details";
};

export default function SideBarWidget({
    sidebarHandler,
    activeItem,
}: SideBarWidgetProps) {
    return (
        <nav className="sidebar">
            <ul className="sidebar__voice-list">
                <li
                    className={`${
                        activeItem === "Users"
                            ? "sidebar__voice-list__item--active"
                            : "sidebar__voice-list__item"
                    }`}
                    onClick={() => sidebarHandler("Users")}
                >
                    <div className="sidebar__voice-list__item__icon-wrapper">
                        <i className="bi bi-person"></i>
                        <p>Users</p>
                    </div>
                </li>
                <li
                    className={`${
                        activeItem === "Pending-Users"
                            ? "sidebar__voice-list__item--active"
                            : "sidebar__voice-list__item"
                    }`}
                    onClick={() => sidebarHandler("Pending-Users")}
                >
                    <div className="sidebar__voice-list__item__icon-wrapper">
                        <i className="bi bi-clock"></i>
                        <p>Pending Users</p>
                    </div>
                </li>
                <li
                    className={`${
                        activeItem === "Add-Users"
                            ? "sidebar__voice-list__item--active"
                            : "sidebar__voice-list__item"
                    }`}
                    onClick={() => sidebarHandler("Add-Users")}
                >
                    <div className="sidebar__voice-list__item__icon-wrapper">
                        <i className="bi bi-plus-circle"></i>
                        <p>Add User</p>
                    </div>
                </li>
            </ul>
        </nav>
    );
}
