import { ChangeEvent, useState } from "react";

export const useInput = <
    T extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
>(
    initialValue: string
) => {
    const [state, setState] = useState(initialValue);

    const onChangeHandler = (event: ChangeEvent<T>) => {
        setState(event.target.value);
    };

    return {
        state,
        onChangeHandler,
    };
};
