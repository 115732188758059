import { useEffect } from "react";
import { useAuth } from "./useAuth";
import { useRouter } from "./useRouter";

type UrlRedirect = string;

export const useRequireAuth = (urlRedirect: UrlRedirect) => {
    const { user, isLogged } = useAuth();
    const router = useRouter();

    useEffect(() => {
        if (!isLogged) {
            router.push(urlRedirect);
        }
    }, [isLogged, router, urlRedirect]);

    return { user, isLogged };
};
