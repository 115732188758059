import { PendingUserSortingObject } from "./components/hooks/useUserFilter";

export const createSortingPendingObject = (
    sortingParameter: PendingUserSortingObject
) => {
    let customSortingCriterion = {
        dimension: sortingParameter.sortingKey,
        rev: sortingParameter.direction,
    };
    return {
        sort: customSortingCriterion,
    };
};
