import { ChangeEvent } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Filter, UserFilterReducerActionType } from "./hooks/useUserFilter";
import labelDictionary from "../labelDictionary.json";

type FilterWidgetProps = {
    filters: Filter;
    dispatch: Function;
    actions: {
        setAccountType: (filter: string) => UserFilterReducerActionType;
        setContractType: (filter: string) => UserFilterReducerActionType;
        setActive: (filter: string) => UserFilterReducerActionType;
        setOwner: (filter: string) => UserFilterReducerActionType;
        setPortfolios: (filter: string) => UserFilterReducerActionType;
        setProfile: (filter: string) => UserFilterReducerActionType;
        setSortBy: (sortingKey: string) => UserFilterReducerActionType;
    };
};

export function FilterWidget({
    filters,
    actions,
    dispatch,
}: FilterWidgetProps) {
    const {
        setAccountType,
        setContractType,
        setActive,
        setOwner,
        setPortfolios,
        setProfile,
    } = actions;

    const onChangeHandler = (action: Function, event: any) => {
        dispatch(action(event.target.value));
    };

    const { AccountType, Profile, ContractType, Owner } = labelDictionary;

    return (
        <Form>
            <Container fluid>
                <Row className="filters-wrapper">
                    <Col md={2} className="filters-wrapper__single-filter-col">
                        <Form.Label>Account Type</Form.Label>
                        <Form.Select
                            value={filters.type}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                onChangeHandler(setAccountType, event)
                            }
                        >
                            <option value="">Choose an option</option>
                            <option value={AccountType.internal}>
                                Internal
                            </option>
                            <option value={AccountType.subscriber}>
                                Subscriber
                            </option>
                            <option value={AccountType.partner}>Partner</option>
                            <option value={AccountType.trial}>Trial</option>
                        </Form.Select>
                    </Col>
                    <Col md={2} className="filters-wrapper__single-filter-col">
                        <Form.Label>Profile</Form.Label>
                        <Form.Select
                            value={filters.profile}
                            onChange={(event) =>
                                onChangeHandler(setProfile, event)
                            }
                        >
                            <option value="">Choose an option</option>
                            <option value={Profile.portfolioManager}>
                                Portfolio Manager
                            </option>
                            <option value={Profile.fundManager}>
                                Fund Manager
                            </option>
                            <option value={Profile.wealthManager}>
                                Wealth Manager
                            </option>
                            <option value={Profile.financialAdvisor}>
                                Financial Advisor
                            </option>
                            <option value={Profile.hedgeFundManager}>
                                Hedge Fund Manager
                            </option>
                            <option value={Profile.familyOffice}>
                                Family office
                            </option>
                            <option value={Profile.broker}>Broker</option>
                            <option value={Profile.others}>Others</option>
                        </Form.Select>
                    </Col>
                    <Col md={2} className="filters-wrapper__single-filter-col">
                        <Form.Label>Contract Type</Form.Label>
                        <Form.Select
                            value={filters.contractType}
                            onChange={(
                                event: ChangeEvent<HTMLSelectElement>
                            ) => {
                                onChangeHandler(setContractType, event);
                            }}
                        >
                            <option value="">Choose an option</option>
                            <option value={ContractType.performance}>
                                Performance
                            </option>
                            <option value={ContractType.fixed}>Fixed</option>
                        </Form.Select>
                    </Col>
                    <Col md={2} className="filters-wrapper__single-filter-col">
                        <Form.Label>Active</Form.Label>
                        <Form.Select
                            value={filters.active}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                onChangeHandler(setActive, event)
                            }
                        >
                            <option value="">All</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                        </Form.Select>
                    </Col>
                    <Col md={2} className="filters-wrapper__single-filter-col">
                        <Form.Label>Owner</Form.Label>
                        <Form.Select
                            value={filters.owner}
                            onChange={(
                                event: ChangeEvent<HTMLSelectElement>
                            ) => {
                                onChangeHandler(setOwner, event);
                            }}
                        >
                            <option value="">Choose an option</option>
                            <option value={Owner.coulterJhon}>
                                Coulter John
                            </option>
                            <option value={Owner.jordanRoland}>
                                Jordan Roland
                            </option>
                            <option value={Owner.marinelliAntonio}>
                                Marinelli Antonio
                            </option>
                            <option value={Owner.nathwaniShiv}>
                                Nathwani Shiv
                            </option>
                            <option value={Owner.pellegrinelliMassimiliano}>
                                Pellegrinelli Massimiliano
                            </option>
                            <option value={Owner.pellegrinelliGuido}>
                                Pellegrinelli Guido
                            </option>
                            <option value={Owner.andreaMontanaro}>
                                Montanaro Andrea
                            </option>
                            <option value={Owner.admin}>Admin</option>
                        </Form.Select>
                    </Col>
                    <Col md={2} className="filters-wrapper__single-filter-col">
                        <Form.Label>Portfolios</Form.Label>
                        <Form.Select
                            value={filters.portfolios}
                            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                onChangeHandler(setPortfolios, event)
                            }
                        >
                            <option value="">All</option>
                            <option value="1">1</option>
                            <option value="3">3</option>
                            <option value="10">10</option>
                        </Form.Select>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
}
